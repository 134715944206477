.linkContact {
    font-size: 20px;
    text-decoration: none;
    color: #333333;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-weight: bold;
}
.contactBlock {
    padding-top: 75px;
    padding-bottom: 40px;
}

.titleContact {
    font-size: 35px;
    font-family: PoppinsBold, Arial, Helvetica, sans-serif;
    line-height: 1.2em;
    color: #333333;
}

.contactWrap {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
}

@media only screen and (max-width: 1000px) {
    .contactWrap {
        width: 90%;
        margin: 0 auto;
        display: block;
        text-align: center;
    }
    .bottomWrapContact {
        margin-top: 40px;
    }
    .contactBlock {
        padding-top: 20px;
        padding-bottom: 15px;
    }
    .titleContact {
        font-size: 30px;
        font-family: PoppinsBold, Arial, Helvetica, sans-serif;
        line-height: 1.2em;
        color: #333333;
    }
}