.headerBlock {
    height: 100px;
    width: 100%;
    display: block;
    position: fixed;
    top:0;
    left: 0;
    z-index: 4;
}
.footerBlock {
    z-index: 4;
    height: 75px;
    width: 100%;
    display: block;
}

.selected {
    color: #512E0B;
    text-decoration: underline;
    font-family: PoppinsBold;
}

.notSelected {
    color: #333333;
    text-decoration: none;
    font-family: PoppinsBold;
}
.imgCloseWrapper {
    width: 25px;
    padding: 5px;
}
.wrapperDecal {
    margin-top: 10px;
    margin-left: 15px;
}
.MobileMenuText {
    font-size: 20px;
    text-decoration: none;
    color: #333333;
}
.headerElem {
    flex:1;
    display: table;
    height: 100%;
    font-size: 17px;
}
.headerElem a {
    display: table-cell;
    vertical-align: middle;
}
.generalBlockFooter {
    display: flex;
    height: 100%;
    text-align: center;
    position: relative;
}

.mobileMenuWrapper {
    position: absolute;
    z-index: 5;
    padding-right: 25px;
    padding-bottom: 15px;
    background-color: #F6E1C8;
    box-shadow: 2px 2px 5px black;
}
.imgBurger {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: 5%;
    width: 30px;
}

.footerElem {
    flex:1;
    display: table;
    height: 100%;
    font-size: 18px;
}

.footerElem a {
    display: table-cell;
    vertical-align: middle;
}
.headerBlockMobile {
    height: 75px;
    width: 100%;
    display: block;
    position: fixed;
    top:0;
    left: 0;
    z-index: 4;
}
@media only screen and (max-width: 1680px) {
    .headerElem {
        font-size: 16px;
    }
}
@media only screen and (max-width: 1400px) {
    .headerElem {
        font-size: 13px;
    }
}

@media only screen and (max-width: 1000px) {
    .footerElem {
        font-size: 15px;
        padding: 5px;
        font-weight: bold;
        flex:auto;
        width: 100%;
        text-align: center;
    }
    .generalBlockFooter {
        display: block;
    }
    .footerBlock {
        height: auto;
    }
}