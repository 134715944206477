
.imageComiteWrap {
    width: 33%;
    display: inline-block;
}
.imageComite {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}
.titreComite {
    margin-top: 10%;
}
.comiteSubTitle {
    font-family: PoppinsBold, Arial, Helvetica, sans-serif;
    font-size: 45px;
    margin-top: 1.5%;
    margin-bottom: 1.5%;
}
.comiteSection {
    padding-bottom: 80px;
}
.middleText2Comite {
    margin-bottom: 2%;
}
.leftTextBlockComite {
    width: 80%;
    display: inline-block;
}
.imageHospitalWrapper {
    width: 20%;
    display: inline-block;
}
.imageHospital {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}
.imageComiteContainer {
    margin-top: 50px;
}

@media only screen and (max-width: 1000px) {
    .leftTextBlockComite {
        width: 100%;
        display: block;
    }
    .imageHospitalWrapper {
        width: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
    .imageComiteWrap {
        width: 100%;
        display: block;
    }
    .comiteBlock {
        height: auto;
    }
    .imageComite {
        margin-top: 25px;
    }
}
