.recommendedSection {
    padding: 20px;
}

.sectionTitle {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.sectionDescription {
    margin-bottom: 20px;
}

.category {
    margin-bottom: 40px;
}

.videoGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between items */
}

.videoItem {
    flex: 0 0 30%; /* Each item takes about 30% of the width */
    box-sizing: border-box;
    margin-bottom: 10px; /* Space between rows */

    background-color: #f6e1c8;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    padding-bottom: 35px;
}

.videoCard {
    background-color: #ffffffbf;
    padding: 10px;
    border-radius: 10px;
    color: #333333;
    height: 100%; /* Allow the card to take full height of the container */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Space out title and image */
    text-align: center;
    overflow: hidden; /* Prevent overflow */
}

.videoCard h4 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; /* Handles long titles with ellipsis */
    margin: 0; /* Remove default margin */
}

.videoImage {
    max-width: 100%;
    max-height: 120px; /* Limit image height to prevent overflow */
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensures the image fits within its container */
    border-radius: 5px;
}
