.imageMission {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.imageMissionWrap {
    width: 33%;
    display: inline-block;
}

.titreMission {
    margin-bottom: 2%;
}

.middleText {
    font-size: 20px;
    color: #333333;
    font-family: PoppinsLight, Arial, Helvetica, sans-serif;
    text-align: justify;
    line-height: 1.3em;
}

.missionBottomText {
    margin-top: 20px;
    font-size: 20px;
    color: #333333;
    font-family: PoppinsLight, Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 1.3em;
    margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {

    .middleText {
        font-size: 15px;
    }

    .missionBottomText {
        font-size: 15px;
    }
    .imageMissionWrap {
        display: block;
        width: 100%;
        margin-top: 20px;
    }
    .titreMission {
        margin-bottom: 20px;
    }
}