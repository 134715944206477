#InscriptionBlock {
    width: 100%;
    margin: 0 auto;
    border-radius: 15px;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #F6E1C8;
}

.boutonInscription {
    background-color: #512E0B;
    color: #ffffff;
    text-align: center;
    width: 250px;
    font-size: 28px;
    padding-top: 5px;
    border-radius: 35px;
    padding-bottom: 5px;
    margin: 0 auto;
    font-family: PoppinsBold, Arial, Helvetica, sans-serif;
    font-weight: bold;
    margin-bottom: 30px;
}

.inscriptionTitle {
    font-family: PoppinsBold, Arial, Helvetica, sans-serif;
    line-height: 1.2em;
    font-size: 30px;
    color: #333333;
    width: 100%;
    text-align: center;
    margin-bottom: 35px;
}

.errorTestez {
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #af0000;
    margin-bottom: 10px;
}

.inscriptionLabel {
    font-family: PoppinsLight, Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: #333333;
}

.formWrap {
    width: 90%;
    margin: 0 auto;
}

.formWrap textarea {
    resize: none;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    color: #333333;
    font-size: 20px;
    outline: none;
    padding: 10px;
    width: 100%;
    height: 90px;
    margin-bottom: 40px;
}

.inscriptionInputs {
    width: 90%;
    padding: 8px;
    background-color: #ffffff;
    border: none;
    border-radius: 5px;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    color: #333333;
    font-size: 20px;
    margin-bottom: 30px;
}

.checkboxTestez {
    width: 20px;
    height: 20px;
    margin: 5px;
    background-color: #333333;
    color: #ffffff;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 30px;
}

.pointTextTestez {
    color: #333333;
    width: 90%;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-weight: bold;
}

.boldVioletTestez {
    font-family: PoppinsBold, Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: #333333;
}

.secondBlockTextTestez {
    margin-top: 2%;
    margin-bottom: 2%;
}

.topTextTestez {
    margin-top: 2%;
    margin-bottom: 2%;
}

.TestFlexBox {
    display: flex;
}

.testLabelWrapper {
    flex: 1;
}

.FlexBoxPoints {
    width: 33%;
    display: flex;
}

@media only screen and (max-width: 1500px) {
    .pointTextTestez {
        font-size: 13.3px;
    }

    .boutonInscription {
        width: 200px;
        font-size: 26px;
        padding-top: 3px;
        border-radius: 5px;
        padding-bottom: 5px;
        margin: 0 auto;
        font-family: Poppins, Arial, Helvetica, sans-serif;
        font-weight: bold;
    }
}

@media only screen and (max-width: 1000px) {
    .TestFlexBox {
        display: block;
    }

    .testLabelWrapper {
        flex: none;
    }

    .FlexBoxPoints {
        width: 100%;
    }

    .pointTextTestez {
        font-size: 12px;
    }

    .boldVioletTestez {
        font-size: 15px;
    }

    .checkboxTestez {
        width: 25px;
        height: 20px;
        margin: 5px;
        background-color: #333333;
        color: #ffffff;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .inscriptionLabel {
        font-size: 15px;
    }
    .formWrap textarea {
        resize: none;
        background-color: #ffffff;
        border: none;
        border-radius: 5px;
        font-family: Poppins, Arial, Helvetica, sans-serif;
        color: #333333;
        font-size: 15px;
        outline: none;
        padding: 5px;
        width: 95%;
        height: 90px;
        margin-bottom: 40px;
    }
    
    .inscriptionInputs {
        width: 95% !important;
        padding: 4px;
        background-color: #ffffff;
        border: none;
        border-radius: 5px;
        font-family: Poppins, Arial, Helvetica, sans-serif;
        color: #333333;
        font-size: 15px;
        margin-bottom: 30px;
    }
    
.boutonInscription {
    width: 150px;
    font-size: 20px;
}
.errorTestez {
    font-size: 15px;
}
}