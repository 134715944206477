.cookieBlock {
    margin-top: 10px;
    position: relative;
    padding-top: 110px;
}

.titreCookie {
    text-align: center;
}

.cookieBlock p {
    color: #333333;
    font-family: PoppinsLight, Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: justify;
}

.cookieBlock li {
    color: #333333;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: justify;
}

.sousTitreCookie {
    font-size: 22px;
    font-family: PoppinsBold, Arial, Helvetica, sans-serif;
    line-height: 1.2em;
    text-align: center;
    margin-top: 45px;
  }
  .personnelCookieSpan {
    font-size: 18px;
    font-style: italic;
  }

  .ulPersonnelle li {
    line-height: 1.4em;
    margin-bottom: 8px;
  }
  .cookieHoliYou {
    color: #333333;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: right;
    margin-top: 15px;
    margin-bottom: 30px;
  }
  
@media only screen and (max-width: 1000px) {
    .cookieBlock ul {
        padding-left: 15px;
    }
}