.generalBlock {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
}

.colorDarkBrown {
  background-color: #F6E1C8;
}

.colorBrown {
  background-color: #d1bdb6;
}

.colorLightBrown {
  background-color: #d1bdb6;
}

.colorVeryLightBrown {
  background-color: #f0dbd4;
}

.colorGrayBrown {
  background-color: #ddcdc6;
}

.section {
  scroll-margin-top: 100px;
}

#contact {
  background-color: rgba(255, 255, 255,0.75);
}
#temoignage {
  background-color: rgba(255, 255, 255,0.75);
}
#comite {
  background-color: rgba(255, 255, 255,0.75);
}
#mission {
  background-color: rgba(255, 255, 255,0.75);
}

.verticalCenter {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.buttonExitPopup {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #333333;
  font-family: PoppinsBold;
  font-size: 30px;
  height: 30px;
  display: flex;
  align-items: center;
}

.validationModal {
  background-image: url('./fonds/Fond.webp');
  width: 50%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  border-radius: 15px;
  text-align: center;
  font-size: 35px;
  font-family: PoppinsLight, Arial, Helvetica, sans-serif;
  line-height: 1.2em;
  position: relative;
}

.validationModal2 {
  background-image: url('./fonds/Fond.webp');
  width: 50%;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  border-radius: 15px;
  text-align: center;
  font-size: 35px;
  font-family: PoppinsLight, Arial, Helvetica, sans-serif;
  line-height: 1.2em;
  position: relative;
}

.verticalHorizontalCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cliquable:hover {
  cursor: pointer;
}

.titreGlobal {
  font-size: 45px;
  font-family: PoppinsBold, Arial, Helvetica, sans-serif;
  line-height: 1.2em;
  padding-top: 60px;
}

.textViolet {
  color: #333333;
}

.middleText {
  font-size: 20px;
  color: #8d7f7d;
  font-family: Poppins, Arial, Helvetica, sans-serif;
  text-align: justify;
  line-height: 1.3em;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: bold;
  src: url("./fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "PoppinsBold";
  src: url("./fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "PoppinsLight";
  src: url("./fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "BodoniModa";
  src: url("./fonts/BodoniModa_9pt-ExtraBold.ttf");
}

@media only screen and (max-width: 1000px) {
  .generalBlock {
    width: 80%;
  }

  #mission {
    margin-top: -40px;
    padding-bottom: 20px;
  }

  .titreGlobal {
    font-size: 35px;
    scroll-margin-top: 75px;
    margin-bottom: 30px;
    padding-top: 20px;
  }

  #testez {
    padding-bottom: 50px;
  }
  .validationModal {
    width: 80%;
    font-size: 20px !important;
  }
  .validationModal2 {
    width: 80%;
    font-size: 20px !important;
  }
  .popupTestText {
    margin-top: 60px;
  }
}
