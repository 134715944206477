.dashboard {
    display: flex;
    background-image: url('../../fonds/fond-v2.webp');
    background-size: cover; /* Adjust to cover the element */
    background-color: #f1dbd5;
    background-repeat: no-repeat;
    color: #333333;
    font-family: 'Poppins', sans-serif;
    width: 100%;
}

.sidebar {
    width: 10%;
    background-color: #f6e1c8;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Ensure content starts from the top */
    height: 100vh; /* Ensure the sidebar takes full height of the viewport */
}

.sidebarTitle {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
}

.sidebarList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebarItem {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333333;
}

.sidebarLink {
    color: #333333;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}

.mainContent {
    width: 90%;
    padding: 20px;
    /*background-color: #222222;*/
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto; /* Allows vertical scrolling */
}

.header {
    color: #333333;
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7); /* White with 30% opacity */
}

.headerTitle {
    font-size: 24px;
    font-weight: bold;
}

.logoutButtonHeader {
    background-color: #512E0B;
    color: #FFFFFF;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

.recommendedSection {
    margin-bottom: 30px;
}

.sectionTitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.sectionDescription {
    font-size: 16px;
    margin-bottom: 20px;
}

.carouselSection {
    padding: 20px;
    margin-bottom: 30px;
}

.carouselContainer {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden ;
    gap: 10px;
}

.carouselItem {
    min-width: 40%;
    background-color: #f6e1c8;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
}

.card {
    background-color: #ffffffbf;
    padding: 15px;
    border-radius: 10px;
    color: #333333;
    height: 240px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures the title and image have space between them */
    overflow: hidden;
}

.card h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; /* Handles title overflow with ellipsis */
    margin: 0; /* Remove default margin to avoid overflow */
}

.videoImage {
    max-height: 200px; /* Limits the image height to prevent overflow */
    width: 100%;
    max-width: 100%; /* Ensure image fits within the card width */
    border-radius: 5px;
    object-fit: cover; /* Ensures the image covers its container */
}
