.solutionMiddleText {
    margin-top: 3%;
}
.ImageCarouselHeight {
    height: 500px;
}
.arrowSolutionR {
    right: 30px;
}
.arrowSolutionL {
    left:30px;
}
.CarouselWrapper {
    margin-top: 40px;
}
.solutionBlock {
    padding-bottom: 20px;
}

@media only screen and (max-width: 1000px) {
    .ImageCarouselHeight {
        height: 230px;
    }
    .arrowSolutionR {
        right: 0;
    }
    .arrowSolutionL {
        left: 0;
    }
}