.presBlockGauche {
    width: 40%;
    color: #333333;
    display: inline-block;
    vertical-align: top;
}

.presBlockDroite {
    width: 50%;
    display: flex;
    float: right;
    height: 100%;
    align-items: center;
}

.presText {
    font-size: 22px;
    line-height: 1.2em;
    font-family: PoppinsLight, Arial, Helvetica, sans-serif;
}
.presText1 {
    margin-left: 20px;
}

.lancement {
    margin-top: 8%;
    margin-bottom: 5px;
}

.newletterWrap {
    width: 80%;
    margin: 0 auto;
}

.titrePresentation {
    font-size: 45px;
    font-family: PoppinsBold, Arial, Helvetica, sans-serif;
    line-height: 1.2em;
}

.logoHoliyou {
    width: 470px;
    margin-left: auto;
    margin-right: auto;
}
.logoHoliyou2 {
    width: 130px;
    height: 130px;
}

.presentationBlock {
    margin-top: 100px;
    height: 450px;
    position: relative;
    padding-top: 75px;
}

.launchPresText {
    position: absolute;
    bottom: 0;
    left: 0;
}
@media only screen and (max-width: 1700px) {
    .titrePresentation {
        font-size: 35px;
    }
    .presText {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1500px) {

    .titrePresentation {
        font-size: 35px;
    }
}

@media only screen and (max-width: 1200px) {

    .titrePresentation {
        font-size: 30px;
    }
}

@media only screen and (max-width: 1000px) {

    .titrePresentation {
        font-size: 32px;
    }

    .logoHoliyou {
        width: 100% !important;
        height: auto !important;
        aspect-ratio: 4/3;
    }

    .presBlockGauche {
        width: 100%;
        color: #333333;
        display: block;
        margin-top: 40px;
    }

    .presBlockDroite {
        width: 100%;
        display: block;
        float: none;
    }

    .presentationBlock {
        margin-top: 75px;
        height: auto;
        padding-top: 20px;
    }
    .launchPresText {
        position: relative;
        margin-top: 50px;
    }
    .presText {
        font-size: 14px;
    }
}