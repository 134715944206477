.temoignageContent {
    width: 90%;
    margin: 15px auto 0 auto;
    text-align: justify;
    font-size: 20px;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #8d807d;
    line-height: 1.3em;
}

.temoignageFooter {
    position: absolute;
    bottom: 20px;
    left: 5%;
    font-size: 20px;
    color: #8d807d;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    line-height: 1.1em;
}
.temoignageImageWrapper {
    flex:1;
}
.temoignageImage {
    width: 100%;
}
.titreTemoignage {
    margin-bottom: 5%;
}
.temoignageWrap {
    height: 350px;
    flex: 1;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 15px;
    position: relative;
    padding: 50px 10px 20px 10px;
    background-color: #f0dbd4;
    color: white;
}
.temoignageFlexBox {
    display: flex;
}

@media only screen and (max-width: 1650px) {
    .temoignageContent {
        font-size: 18px;
    }
    .temoignageFooter {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1400px) {
    .temoignageContent {
        font-size: 16px;
    }
    .temoignageFooter {
        font-size: 16px;
    }
}
@media only screen and (max-width: 1200px) {
    .temoignageContent {
        font-size: 14px;
    }
    .temoignageFooter {
        font-size: 14px;
    }
}
@media only screen and (max-width: 1000px) {
    .temoignageFlexBox {
        display: block;
    }
    .temoignageWrap {
        margin-top: 50px;
    }
}