
.sousTitreCGU {
  font-size: 18px;
  font-family: PoppinsBold, Arial, Helvetica, sans-serif;
  line-height: 1.2em;
  margin-left: 30px;
  margin-top: 25px;
  color: #333333;
}

.titreSubCGU {
  margin-top: 15px;
  text-align: center;
  font-size: 25px;
  font-family: PoppinsLight;
  line-height: 1.2em;
  padding-bottom: 15px;
}