.soutientWrapper {
    display: flex;
    align-items: center;
}
.soutientImg {
    width: 100%;
    height: 100%;
}
.soutientImg2 {
    width: 100%;
    height: 100%;
}
.soutientWrapperMargin {
    margin-top: 50px;
}
.soutientImgContainer {
    flex:1;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.soutientImgContainer2{
    flex:1;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
}
@media only screen and (max-width: 1000px) {
    .soutientWrapperMargin {
        margin-top: 20px;
    }
    .soutientImgContainer {
        padding-left: 5px;
        padding-right: 5px;
    }
    .soutientImgContainer2{
        padding-left: 5px;
        padding-right: 5px;
    }
}